.container-fluid{
	& > div {
  		height: 100vh;
  		@include media-width($large) {
  			height: 100vh;
  		}
  	}
}
#main {
	.ilustracion-fondo {
		padding: 0;
		position: absolute;
	    top: 50%;
	    left: 0;
	    -webkit-transform: translate(-50%, -50%);
	    transform: translateY(-50%);
	    width: 130%;
	    max-height: 101%;
	    height: 101%;
	    overflow: hidden;
		@include media-width($quinientos) {
			width: 120%;		
		}
		@include media-width($medium) {
    	    width: 100%;
		}
		@include media-width($large) {
			height: 101vh;
		}
		/*IPAD PRO*/
		@media screen and (min-width: 1024px) and (min-height: 1366px){
			height: 100vh;
  			overflow: hidden;
		}
		img{
			&.d-block{
	    	    height: auto;
    			width: 100%;
			}
		    &.desktop{
		        height: 100%;
    			float: left;
    			position: absolute;
    			@include media-width($xlarge){
    				top: -5%;
    				height: 110%;
    			}
    			&.d-left{
    				left: -8%;
    				@include media-width($large) {
    					left: 0;
    				}
    			}
    			&.d-right{
    				right: -10%;
    				@include media-width($large) {
    					right: -2%;
    				}
    			}
		    }
		}
	}
	.contenedor{
		height: 100vh;
		position: relative;
		text-align: center;
	    max-width: 280px;
		margin: 0 auto; 
		@include media-width($small) {
			max-width: 330px;
		}
		@include media-width($quinientos) {
			max-width: 70%;
		}
		@include media-width($medium) {
			min-width: 60%;
		}
		@include media-width($large) {
			height: 100vh;
			min-width: 50%;
		}
		.main-text {
			width: 100%;
		    height: calc(100vh - 100px);
		    position: absolute;
		    left: 0;
		    top: 50%;
		    transform: translateY(-50%);
		    z-index: 1;
		    @media screen and (min-height: 568px){
	    	    height: auto;
		    }
		    @include media-width($small) {
		    	top: 40%;
		    }
		    // @include media-width($cuatro14) {
		    // 	padding-top: 9rem;
		    // }
		    & > div{
		    	margin-bottom: .5rem;
		    	@include media-width($small) {
		    		margin-bottom: 0;
		    	}
		    }
		    .contador{
		    	color: $orange-color;
		    	& > p{
		    		letter-spacing: 1px;
		    		@include fluid-type(11px, 16px);
		    	}
		    	.contador-numeros{
		    		@include fluid-type(18px, 20px);
		    		.desbloqueadas{
		    			color: $yellow-color;
		    		}
		    	}
		    }
		    .logo-libro{
		    	img{
		    		max-width: 130px;
		    		@include media-width($large){
		    			max-width: 200px;
		    		}
		    	}
		    }
		    .logo-kilo{
		    	display: inline-block;
		    	margin: 5px 0;
		    	@include media-width($large){
		    		margin: 15px 0;
		    	}
		    	img{
		    		max-width: 30px;
		    	}
		    }
		}
		.mounstro-bottom {
		    position: absolute;
		    bottom: -5px;
		    left: 0;
		    @include media-width($medium) {
	    	    bottom: -35px;
		    }
		    @include media-width($large) {
	    	    max-width: 600px;
	    		left: 50%;
	    		transform: translateX(-50%);
		    }
		    img{
		    	width: 100%;
		    }
		}
	}
}
.main-aviso{
	h1{
		@include fluid-type(20px, 25px);
		color: $orange-color;
		margin: 30px 0 15px;
	}
	.aviso-txt{
		max-height: 40vh;
	    overflow-y: scroll;
	    margin-top: 0rem;
	    @include media-width($large) {
		    max-height: 60vh;
		}
		p{
			margin-bottom: 10px;
			text-align: justify;
		}
		ol{
			padding-left: 15px;
			li{
				font-family: $helvetica;
				@include fluid-type(12px, 14px);
				text-align: justify;
				margin-bottom: 10px;
			}
		}
		.logos{
	    	img{
	    		max-width: 30px;
	    	}
	    }
	}
}
.main-derecho{
	h1{
		@include fluid-type(14px, 20px);
		color: $orange-color;
		margin: 0 0 15px;
		@include media-width($large){
			margin: 30px 0 15px;
		}
	}
}
.landscape{
	display: none;
	visibility: hidden;
	background-color: $orange-color;
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 99;
	&.active{
		display: block;
		visibility: visible;
	}
	img{
		max-width: 50%;
	    position: absolute;
	    left: 45%;
	    top: 50%;
	    transform: translate(-50%, -50%);
	}
}

