.btn-orange{
  background-color: #f47d20;
  border-radius: 10px;
  color: #ffffff;
  font-family: $bold;
  font-size: 11px;
  min-width: 120px;
  text-transform: uppercase;
  @include fluid-type(12px, 16px);
  @include media-width($small) {
    min-width: 140px;
  }
  @include media-width($medium) {
    min-width: 200px;
  }
  @include media-width($large) {
    min-width: 160px;
  }
  &__link {
    background-color: transparent;
    border: none;
    color: #f47d20;
    text-decoration: underline;
    @include fluid-type(12px, 16px);
    font-family: $bold;
    display: inline-block;
    text-transform: uppercase;
    padding: 0;
    &:hover{
      color: $yellow-color;
    }
  }
  &:hover{
    color: $white-color;
    background-color: $yellow-color;
  }
}