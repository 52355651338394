// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
footer{
	position: fixed;
	bottom: 10px;
	right: 10px;
	left: 10px;
	height: 60px;
	@include media-width($large){
		height: 110px;
	}
	.terminos-aviso {
        font-size: 10px;
	    text-align: left;
	    .logos{
	    	img{
	    		max-width: 30px;
	    	}
	    }
	    ul{
	    	margin: 40px 0 0;
	    	display: block;
	    	@include media-width($large){
				margin: 5px 0;
	    	}
	    	li{
	    		display: inline-block;
	    		float: left;
	    		font-weight: bold;
	    		margin-right: 10px;
	    		a{
	    			color: $orange-color;
	    			text-decoration: underline;
	    			font-family: $helvetica;
	    			font-size: 8px;
	    			text-transform: uppercase;
	    			@include media-width($medium){
	    				font-size: 12px;
	    			}
	    		}
	    	}
	    }
	    span{
	    	display: block;
	    	float: none;
	    	font-family: $helvetica;
	    }
	}
	.psm{
		p{
			text-align: right;
		    font-size: 10px;
		    font-family: $helvetica;
		    @include media-width($large){
		    	margin-top: 35px;
		    }
		    a{
		    	display: inline-block;
		    
				img{
					max-width: 70px;
					@include media-width($medium){
						max-width: 120px;
					}
				}
			}
		}
	}
}