@charset 'UTF-8';
// Configuration and helpers
// -------------------------------
@import 'abstracts/reset';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';
/**
 * ---------------------------------------------------
 * 	Table of contents
 * ---------------------------------------------------
 */
/**----------
	1. Base
		1.1 base
		1.2 Buttons
		1.3 Fonts
		1.4 Typography
		1.5 Helpers
		1.6 Reset
		1.7 Modales
	2. Layout
		2.1 Header 
		2.2 Footer
	3. Pages
		3.1 Main
		3.2 Libro


---------------*/
/**
 * ---------------------------------------------------
 * 	1.  Base
 * ---------------------------------------------------
 */

/* --------------------- 1.1 Base --------------------- */ 
@import 'base/base';
/* --------------------- 1.3 Buttons --------------------- */ 
@import 'base/buttons';
/* --------------------- 1.4 Fonts --------------------- */ 
@import 'base/fonts';
/* --------------------- 1.5 Typography --------------------- */ 
@import 'base/typography';
/* --------------------- 1.6 Helpers --------------------- */ 
@import 'base/helpers';
/* --------------------- 1.7 Reset --------------------- */ 
@import 'base/modales';


/**
 * ---------------------------------------------------
 * 	2.  Sections
 * ---------------------------------------------------
 */
/* --------------------- 2.1 Header --------------------- */ 
@import 'layout/header';
/* --------------------- 2.2 Footer --------------------- */ 
@import 'layout/footer';



/**
 * ---------------------------------------------------
 * 	3.  Pages
 * ---------------------------------------------------
 */
/* --------------------- 3.1 Home page --------------------- */ 
@import 'pages/main';
/* --------------------- 3.1 Home page --------------------- */ 
@import 'pages/final';
/* --------------------- 3.1 Libro derecha --------------------- */ 
@import 'pages/libro';


