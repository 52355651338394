/**
 * Basic typography style for copy text
 */
body {
  color: $dark-grey-color;
  font-style: normal;
  font-weight: 400;
  overflow: hidden;
}
p{
	// font-family: $helvetica;
	@include fluid-type(12px, 14px);
}
.p-uppercase{
	text-transform: uppercase;
  	font-family: $ultra; 
}
