#libro{
    display: none;
}
.sombra-libro {
    @include media-width($medium){
        &:after {
            content: '';
            background-image: url('#{$ruta-img}/sombra-libro.png');
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 100%;
            position: absolute;
            top: 0;
            left: 50%;
            width: 20%;
            z-index: 0000000;
            transform: translateX(-50%);
            opacity: .5;
        }
    }
}
.pagina{
    overflow-x: hidden;
    // overflow-y: scroll;
    overflow-y: hidden;
    height: 100vh;
    
    .contenedor{
        overflow-y: scroll;
        overflow-x: hidden;
        height: auto;
        z-index: 2;
        .texto{
            @include media-width($medium){
                height: 40vh;
                margin-top: 30px;
                overflow: scroll;
            }
            p{
                font-family: $regular;
                text-align: justify;
                margin: 0 0 30px;
            }
            br {
                display: block !important;
                margin: 14px 0 !important;
                line-height: 14px;
                content: '';
            }
            .text-tooltip{
                font-family: $ultra;
                color: $red-color;
            }
            .btn-desbloquear{
                background: $orange-color;
                color: $white-color;
                display: inline-block;
                width: 40px;
                padding: 2px 0 0;
                @include fluid-type(11px, 16px);
                line-height: 14px;
                text-align: center;
                border-radius: 5px;
                vertical-align: middle;


                animation-name: parpadeo;
                animation-duration: 1s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                -webkit-animation-name:parpadeo;
                -webkit-animation-duration: 1s;
                -webkit-animation-timing-function: linear;
                -webkit-animation-iteration-count: infinite;
                @include media-width($large){
                    width: 60px;
                    line-height: 17px;
                }
            }
            // .texto-bloqueado{
            //     display: none;
            // }
        }
        .caja-botones{
            position: absolute;
            bottom: 8%;
            left: 50%;
            transform: translateX(-50%);
            width: 100% !important;
            & > a{
                margin-bottom: 10px;
            }
            @include media-width($medium){
                position: relative;
                // margin-top: 30px;
                bottom: 0;
            }
            span {
                display: block;
                text-decoration: none;
                color: $dark-grey-color;
                font-family: $bold;
                @include fluid-type(12px, 16px);
            }
        }
    }
    .caja-botones{
        width: 100% !important;
        text-align: center;
    }
    nav{
        width: 100%;
        .pagination{
            width: 180px;
            margin: 30px auto 0;
            .page-item{
                margin-right: 10px;
                &:first-child{
                    .page-link{
                        border: none;
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
                &:last-child{
                    margin-right: 0;
                    .page-link{
                        border: none;
                        font-size: 18px;
                        line-height: 30px;
                    }
                }
                .page-link{
                    background-color: transparent;
                    padding: 0;
                    line-height: 26px;
                    height: 30px;
                    width: 30px;
                    border: 2px $orange-color solid;
                    border-radius: 50%;
                    color: $orange-color;
                    font-family: $bold;
                    font-size: 14px;
                    text-align: center;
                }
            }
        }
    }
    &-1{
        .ilustracion{
            padding: 16% 0 0 0;
            max-height: 50vh;
            overflow: hidden;
            @include media-width($quinientos){
                margin-top: 10%;
                padding: 0;
                overflow: hidden;
                max-height: 42vh;
            }
            @include media-width($medium){
                height: 100vh;
                margin: 0;
                max-height: unset;
            }
            img{
                width: 100%;
                position: relative;
                left: 0;
                bottom: 0;
                @media screen and (min-width: $xsmall) and (max-height: $iphone5alto) {
                    bottom: -10px;
                }
                @include media-width($quinientos){
                    bottom: 0;
                }
                @include media-width($medium){
                    height: auto;
                    width: 100%;
                    left: 0;
                    bottom: -50%;
                    transform: translateY(-50%);
                }
            }
            .zoom-img{
                max-width: 30px;
                top: 10vh;
                z-index: 9;
                left: 0;
                // transform: translateX(-50%);
                display: block;
                position: absolute;
                background-color: $orange-color;
                border-radius: 50%;
                height: 30px;
                padding: 5px;
                margin-left: 10px;
                span{
                    position: absolute;
                    font-size: 10px;
                    width: 170px;
                    left: 35px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                img{
                    width: 100%;
                }
            }
        }
        .contenedor{
            padding: 10px 30px 20px;
            margin-bottom: 0px;
            margin-top: 0;
            height: 30vh;
            @include media-width($cuatro14){
                position: relative;
                text-align: center;
                overflow-x: hidden;
            }
            @include media-width($quinientos){
                margin-top: 20px;
                max-height: 40vh;
            }
            @include media-width($medium){
                min-height: 100vh;
                margin-top: 0;
                margin-bottom: 0;
            }
            .align-vertical{
                position: relative;
                top: 0;
                left: 0;
                transform: unset;
                width: 100%;
                margin: 0 auto;
                // IPHONE 6 7 8
                @media screen and (min-width: $small) and (max-height: 667px) {
                    top: 0%;
                }
                @media screen and (min-width: $cuatro14) and (max-height: 667px) {
                    width: 90%;
                    margin: 0 auto;
                }
                @include media-width($medium){
                    padding: 0;
                    height: auto;
                    top: 50%;
                    transform: translateY(-50%);
                }
                @include media-width($large){
                    width: 65%;
                    max-width: 350px;   
                }
                .header-pagina{
                    img{
                        max-width: 50%;
                    }
                }
            }
        }
    }
    &-2{
        .ilustracion{
            padding: 0;
            max-height: 50vh;
            overflow: hidden;
            @include media-width($quinientos){
                padding: 0;
                overflow: hidden;
                // max-height: 320px;
                max-height: 55vh;
            }
            @include media-width($medium){
                height: 100vh;
                margin: 0;
                max-height: unset;
            }
            img{
                width: 100%;
                position: relative;
                left: 0;
                top: 0;
                @media screen and (min-width: $xsmall) and (max-height: $iphone5alto) {
                    bottom: -10px;
                }
                // @include media-width($quinientos){
                //     bottom: 10%;
                // }
                @include media-width($medium){
                    height: auto;
                    width: 100%;
                    top: unset;
                    left: 0;
                    bottom: -50%;
                    transform: translateY(-50%);
                }
            }
            .zoom-img{
                max-width: 30px;
                top: 10vh;
                z-index: 9;
                left: 0;
                // transform: translateX(-50%);
                display: block;
                position: absolute;
                background-color: $orange-color;
                border-radius: 50%;
                height: 30px;
                padding: 5px;
                margin-left: 10px;
                img{
                    width: 100%;
                }
                span{
                    position: absolute;
                    font-size: 10px;
                    width: 170px;
                    left: 35px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
        .contenedor{
            padding: 0 30px 0;
            margin-bottom: 0;
            margin-top: 70px;
            height: calc(40vh - 100px);
            @include media-width($cuatro14){
                position: relative;
                text-align: center;
            }
            @include media-width($medium){
                min-height: 100vh;
                margin-top: 0;
                margin-bottom: 0;
            }
            @include media-width($large){
                height: 100vh;
            }
            .align-vertical{
                position: relative;
                top: 0;
                left: 0;
                transform: unset;
                width: 100%;
                padding-top: 10px;
                margin: 0 auto;
                @media screen and (min-width: $cuatro14) and (max-height: 667px) {
                    top: 0;
                    width: 90%;
                    margin: 0 auto;
                }
                @include media-width($medium){
                    padding: 0;
                    height: auto;
                    top: 50%;
                    transform: translateY(-50%);
                }
                @include media-width($large){
                    width: 65%;
                    max-width: 350px;   
                }
                .header-pagina{
                    img{
                        max-width: 60%;
                    }
                }
            }
        }
    }
    &-full{
        .ilustracion{
            div:nth-child(2){
                padding: 0;
                max-height: 100vh;
                max-width: 100%;
                overflow: hidden;
                position: relative;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                @include media-width($medium){
                    width: 85%;
                    // max-height: 100%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
                img{
                    width: 70%;
                }
                h1{
                    text-align: center;
                }
            }
        }
        .zoom-img{
            width: 30px;
            top: 10vh;
            z-index: 9;
            left: 0;
            // transform: translateX(-50%);
            display: block;
            position: absolute;
            background-color: $orange-color;
            border-radius: 50%;
            height: 30px;
            padding: 5px;
            margin-left: 10px;
            img{
                width: 100%;
            }
            span{
                position: absolute;
                font-size: 10px;
                width: 170px;
                left: 35px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
        .contenedor{
            position: absolute;
            bottom: 0;
        }
    }
    &#p3{
        .ilustracion{
            img{
                bottom: 30px;
                @include media-width($medium){
                    bottom: -50%;
                }
            }
        }
    }
    &#p5{
        .ilustracion{
            max-height: 60vh;
            @include media-width($medium){
                max-height: 100vh;
            }
            img{
                @media screen and (min-width: $xsmall) and (max-height: 586px){
                    bottom: 30px;
                }
                @include respond-to(iPhone6) {
                    bottom: 20px;
                }
                @include media-width($medium) {
                    bottom: -50%;   
                }
                @include media-width($large) {
                    bottom: -58%;
                    left: 30px;
                    width: 90%;
                }
            }
            .zoom-img{
                img{
                    left: 0;
                }
            }
        }
        .contenedor{
            margin-top: 20px;
            max-height: 30vh;
        }
    }
    &#p7{
        .ilustracion{
            max-height: 60vh;
            @include media-width($medium){
                max-height: 100vh;
                img{
                    bottom: -55%;
                }
            }
        }
    }
    &#p9{
        .ilustracion{
            max-height: 60vh;
            @include media-width($medium){
                max-height: 100vh;
            }
        }
        .contenedor{
            max-height: 30vh;
            @include media-width($medium){
                min-height: 100vh;
            }
        }
    }
    &#p10{
        .ilustracion{
            max-height: 60vh;
            @include media-width($medium){
                max-height: 100vh;
                img{
                    top: unset;
                    bottom: 0;
                    position: absolute;
                    transform: unset;
                }
            }
        }
        .contenedor{
            max-height: calc(50vh - 90px);
            @include media-width($medium){
                max-height: 100vh;
            }
        }
    }
    &#p11{
        .ilustracion{
            max-height: 60vh;
            @include media-width($medium){
                max-height: 100vh;
                img{
                    left: -40px;
                    bottom: -55%;
                }
                .zoom-img{
                    span{
                        position: absolute;
                        font-size: 10px;
                        width: 170px;
                        left: 35px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    img{
                        left: unset;
                        bottom: unset;
                        transform: unset;
                    }
                }
            }
        }
        .contenedor{
            margin-top: 0;
            max-height: 30vh;
        }
    }
    &#p12{
        .ilustracion{
            div{
                p{
                    font-family: $regular;
                }
            }
        }
    }
    &#p13, 
    &#p14,
    &#p15,
    &#p16{
        .ilustracion{
            img{
                width: auto;
                height: 80%;
                left: 50%;
                top: 50%;
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
            }
        }
        @include media-width($large){
            .ilustracion{
                img{
                    width: 70%;
                    height: auto;
                    left: 50%;
                    top: unset;
                }
            }
        }
        
    }
    &#p17{
        .ilustracion{
            max-height: 60vh;
            @include media-width($large){
                max-height: 100vh;
            }
            img{
                width: 80%;
                bottom: 80px;
                left: 50%;
                transform: translateX(-50%);
                @include media-width($large){
                    width: 100%;
                    bottom: -10px;
                    left: 0;
                    transform: unset;
                }
            }
        }
        .contenedor{
            height: 30vh;
            @include media-width($large){
                height: 100vh;
            }
        }
    }
}
.paginador{
    position: absolute;
    top: 50%;
    display: none;
    z-index: 9999;
    transform: translateY(-50%);
    font-size: 40px;
    left: 1rem;
    color: $orange-color;
    transition: .3s;
    @include media-width($medium){
        font-size: 60px;
    }
    &.btn-next{
        right: 1rem;
        left: unset;
        &:hover{
            transform: translate(0.5rem, -50%) scale(1.1);
        }
    }
    &:hover{
        color: $yellow-color;
        transform: translate(-0.5rem, -50%) scale(1.1);
    }
}



@-moz-keyframes parpadeo{  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

@-webkit-keyframes parpadeo {  
  0% { opacity: 1.0; }
  50% { opacity: 0.0; }
   100% { opacity: 1.0; }
}

@keyframes parpadeo {  
  0% { opacity: 1.0; }
   50% { opacity: 0.0; }
  100% { opacity: 1.0; }
}

