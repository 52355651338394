// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@include font-face('AmsiPro-Black', '../fonts/AmsiPro/AmsiPro-Black/AmsiPro-Black');
@include font-face('AmsiPro-Bold', '../fonts/AmsiPro/AmsiPro-Bold/AmsiPro-Bold');
// @include font-face('AmsiPro-Ultra', '../fonts/AmsiPro/AmsiPro-Ultra/AmsiPro-Ultra');
@include font-face('AmsiProNarw-Ultra', '../fonts/AmsiPro/AmsiProNarw-Ultra/AmsiProNarw-Ultra');
@include font-face('AmsiProNarw-Bold', '../fonts/AmsiPro/AmsiProNarw-Bold/AmsiProNarw-Bold');
@include font-face('AmsiPro-Regular', '../fonts/AmsiPro/AmsiPro-Regular/AmsiPro-Regular');
@include font-face('AmsiProCond-Ultra', '../fonts/AmsiPro/AmsiProCond-Ultra/AmsiProCond-Ultra');
@include font-face('HelveticaNeueLTStd-LtCn', '../fonts/HelveticaNeueLTStd-LtCn/HelveticaNeueLTStd-LtCn');

@include font-face('fontawesome', '../fonts/font-awesome/fa-regular-400');
