// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


@mixin clearfix{
  &::after {
    visibility: hidden;
    display: block;
    font-size: 0;
    content: " ";
    clear: both;
    height: 0;
  }
  display: inline-block;
}

$xsmall: 320px;
$small: 375px;
$cuatro14: 414px;
$quinientos: 500px;
$medium: 768px;
$large: 920px;
$xlarge: 1024px;

$iphone5alto: 568px;

@mixin media($media){
  @media all and (max-width: #{map-get($breakpoints,#{$media})}){
    @content
  }
}
@mixin media-width($media-width){
  @media screen and (min-width: #{$media-width}){
    transition: .5s; // animate when window resizing
    @content
  }
}

@mixin respond-to($media) {
  @if $media == iPhone5 {
    @media only screen and (min-device-width : 320px) and (max-device-width : 568px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  }
  @else if $media == iPhone6 {
    @media only screen and (min-device-width : 375px) and (max-device-width : 667px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio: 2) {
      @content;
    }
  }
  @else if $media == iPhone6Plus {
    @media only screen and (min-device-width : 414px) and (max-device-width : 736px) and (-webkit-min-device-pixel-ratio: 3) and (orientation : portrait) {
      @content;
    }
  }
}

//--------------------------------------------------------------------------------
// ------------ Metarial Design Boxshadow Mixin
//--------------------------------------------------------------------------------
// Created by @KingRayhan
// @url https://codepen.io/kingRayhan/pen/gWrKyz

@mixin mboxshadow($depth:1) {
  @if $depth == 1 {
        box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  @else if $depth == 2 {
      box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  }
  @else if $depth == 3 {
      box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }
  @else if $depth == 4 {
  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
  @else if $depth == 5 {
       box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  }
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}


// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
    eot: "?",
    svg: "#" + str-replace($name, " ", "_")
  );

  $formats: (
    otf: "opentype",
    ttf: "truetype"
  );

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
  }
}


// =============================================================================
// Mixin: Fluid Type
// =============================================================================

/// Magic calc + vh to allow text to be fluid between minimum
/// and maximum breakpoints.
///
/// @group typography
/// @param {variable} $min-font-size [12px] - Minimum font size 
/// @param {variable} $max-font-size [24px] - Maximum font size
/// @param {variable} $lower-range [420px] - Stop scaling font smaller at this screen resolution 
/// @param {variable} $upper-range [900px] - Stop scaling font larger at this screen resolution 
/// @example
///   h1 {
///     @include responsive-type(20px, 48px);
///   }
/// @site https://madebymike.com.au/writing/precise-control-responsive-typography/

@mixin fluid-type($min-font-size: 12px, $max-font-size: 21px, $lower-range: 320px, $upper-range: 920px) {

  font-size: calc(#{$min-font-size} + #{(($max-font-size / ($max-font-size * 0 + 1)) - ($min-font-size / ($min-font-size * 0 + 1)))} * ( (100vw - #{$lower-range}) / #{(($upper-range / ($upper-range * 0 + 1)) - ($lower-range / ($lower-range * 0 + 1)))}));
  
  @media screen and (max-width: $lower-range) {
    font-size: $min-font-size;
  }
  @media screen and (min-width: $upper-range){
    font-size: $max-font-size;
  }
}
