header{
	@media screen and (max-width: 768px) {
		height: 60px;
	    position: absolute;
	    top: 0;
	    width: 100%;
	    background: linear-gradient(1deg, rgba(255,255,255,0) 0%, rgba(255,255,255,.65) 130%);
	    z-index: 3;
	}
	nav{
		z-index: 998;
	    position: absolute;
	    height: 100vh;
	    width: 100%;
	    // overflow: hidden;
	    left: -100%;
	    transition: .3s;
	    @include media-width($large) {
	    	height: 100px;
	    	left: 0;
	    }
	    &.fs{
			left: 0;
	    }
	    .menu, 
	    .menu-bg, 
	    .menu-burger {
			position: absolute;
			width: 50px;
			height: 50px;
			font-size: 30px;
			text-align: center;
			border-radius: 0;
			left: 5px;
			top: 5px;
			@include media-width($small) {
				left: 15px;
				top: 15px;
			}
			@include media-width($medium) {
				font-size: 40px;
				right: 40px;
		  	}
			@include media-width($large) {
			    left: 5%;
			    top: 25px;
			    font-size: 40px;
			}
		}
		.menu-bg{
			background-image: url('#{$ruta-img}/back-menu.png');
			background-repeat: no-repeat;
			background-size: cover;
			pointer-events: none;
			transition: .3s;
			left: -65%;
			top: 0;
			-webkit-transform-origin: center center;
			transform-origin: center center;
			z-index: 99;
			width: 65%;
			height: 100vh;    
			opacity: 0;
			@include media-width($medium) {
				width: 50%;
			}
			@include media-width($large) {
				background-image: url('#{$ruta-img}/back-menu-top.png');
			    background-position: center;
			    background-size: cover;
			    background-repeat: no-repeat;
			    right: unset;
			    top: -110%;
			    left: 0;
			    width: 100%;
			    height: 100%;
			}
			&.fs{
				transform: translateX(100%);
	  			opacity: 1;
	  			@include media-width($large) {
	  				transform: translateY(100%);
	  			}
			}
		}
		.menu-items{
			position: absolute;
			font-weight: bold;
			@include fluid-type(12px, 14px);
			letter-spacing: 2px;
			color: white;
			opacity: 0;
			transition: .4s;
			pointer-events: none;
			z-index: 999;
			width: 40%;
			height: 70%;
			left: 0;
			top: 48%;
			transform: translateY(-50%);
			@include media-width($medium) {
				font-size: 22px;
				width: 30%;
				top: 54%;
			}
			@include media-width($large) {
				width: 100%;
				height: 50%;
				top: 10%;
				transform: unset;
				font-size: 14px;
				padding: 0 4% 0 6%;
				font-size: 16px;
			}
			&.fs{
				pointer-events: auto;
	  			opacity: 1;
	  			a{
					opacity: 1;
					left: 0px;
					text-align: center;
					padding-left: 25px;
					font-family: $bold; 
					text-transform: uppercase;
					@include media-width($large) {
						left: unset;
						opacity: 1;
						top: 0;
						width: auto;
						text-align: center;
					}
	  			}
			}
			a{
				transition: all .5s ease;
				opacity: .5;
				display: block;
				text-decoration: none;
				color: white;
				position: relative;
				left: -200px;
				height: calc(100% / 8);
				line-height: 2.6rem;
				@include fluid-type(12px, 14px);
				@include media-width($large) {
					transition: all 1s ease;
					display: inline-block;
					height: auto;
					// width: calc(90% / 6);
					width: auto;
					line-height: normal;
					left: unset;
					top: -46px;
					opacity: 0;
					text-align: center;
					padding-left: 25px;
				}
				&:first-child{
					img{
						width: 80px;
	  					vertical-align: top;
	  					@include media-width($medium) {
	  						width: 100px;
	  					}
	  					@include media-width($large) {
	  						width: 70px;
	    					vertical-align: middle;
	  					}
					}
				}
				&:nth-child(2){
					transition: all .6s ease;
					@include media-width($large) {
						transition: all .9s ease;
					}
					.avatar-dinamico{
						width: 24%;
						position: absolute;
						right: 5px;
						top: 48%;
						transform: translateY(-50%);
						@include media-width($large) {
							width: 20%;
						    position: relative;
						    display: inline-block;
						    left: unset; 
						    top: 10px; 
						    transform: unset; 
						}
					}
					.nombre-dinamico{
						width: 60%;
						position: absolute;
						left: 25px;
						top: 50%;
						text-align: left;
						display: inline;
						font-size: 20px;
						line-height: 13px;
						bottom: unset;
						transform: translateY(-50%);
						font-family: $bold;
						text-transform: uppercase;
						letter-spacing: 0;
						@include media-width($large) {
							font-size: 22px;
							text-align: left;
							position: relative;
							left: unset; 
							top: 10px;
							display: inline-block;
							margin: 0;
							line-height: 14px;
							bottom: unset; 
							transform: unset; 
							letter-spacing: 0;
						}
						.submenu{
							font-size: 10px;
							letter-spacing: 0;
						}
					}
				}
				&:nth-child(3){
					transition: all .7s ease;
					@include media-width($large) {
						transition: all 1.1s ease;
					}
				}
				&:nth-child(4){
					transition: all .8s ease;
					img{
					    vertical-align: middle;
				        max-width: 100px;
					}
				}
				&:nth-child(5){
					transition: all .9s ease;
				}
				&:nth-child(6){
					transition: all 1s ease;
				}
				&:nth-child(7){
					transition: all 1.1s ease;
					img.descarga-ebook {
					    vertical-align: middle;
					    max-width: 100px;
					}
				}
				&:nth-child(8){
					transition: all 1.2s ease;
					img{
					    vertical-align: middle;
				        max-width: 100px;
					}
				}
			}
		}	
	}
	.menu-burger {
		color: #f47d20;
		-webkit-user-select: none;
		cursor: pointer;
		transition: .4s;
		-webkit-transform-origin: center;
		transform-origin: center;
		z-index: 9999999;
		left: 5px;
		position: absolute;
		width: 50px;
		height: 50px;
		font-size: 30px;
		text-align: center;
		border-radius: 0;
		left: 5px;
		top: 5px;
		&.fs{
			color: #ffffff;
  			-webkit-transform: rotate(-180deg) translateY(10px);
          	transform: rotate(-180deg) translateY(10px);
          	z-index: 999;
          	@include media-width($medium){
				color: #ffffff;
			  	-webkit-transform: rotate(-180deg) translateY(-22px);
	          	transform: rotate(-180deg) translateY(-22px);
          	}
          	@include media-width($large){
				color: #ffffff;
				-webkit-transform: rotate(180deg) translateY(-10px);
				transform: rotate(180deg) translateY(-10px);
          	}
		}
		@include media-width($large) {
			left: 15px;
    		top: 15px;
		}
	}
	.caja-botones{
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 9;
		&.active{
			display: block;
			visibility: visible;
		}
		@include media-width($small) {
		    top: 20px;
			right: 25px;
		}
		a{
			display: inline-block;
			width: 30px;
			height: 30px;
		    margin-left: 5px;
			img{
				width: 100%;
				
			}
		}
	}
	.logo-top{
		position: absolute;
	    left: 6%;
	    top: 2%;
	    width: 200px;
	    z-index: 1000;
		img{
			width: 50px;
			display: inline-block;
			float: left;
			margin-right: 10px;
		}
		a{
			display: inline-block;
			float: left;
			margin-right: 10px;
			&:nth-child(2){
				width: 30px;
			    margin-top: 10px;
			    img{
			    	width: 100%;
			    }
			}
			&:nth-child(3){
				width: 90px;
			   	margin-top: 5px;
			   	img{
			   		width: 100%;
			   	}
			}
		}
	}
}