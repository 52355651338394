// Base colors
$body-color: #333;
$p-color: #575757; // <p> color gris del texto normal
// $h-color: #111; // headings color
// $a-color: #337ab7; // links color
$special-words: #e71800; //rojo de palabras especiales
$dark-grey-color: #454545; //Gris oscuro en MODALES
$orange-color: #f47d20;
$yellow-color: #f1c32e;
$red-color: #e71800;
$fb-color: #3B5998;
$green-color: #7fc972;

$magenta-color: #BA2E8B;
$blue-color: #2BADFE;
$white-color: #ffffff;


// Fonts variables
$black: 'AmsiPro-Black';
$bold: 'AmsiProNarw-Bold';
$ultra: 'AmsiProNarw-Ultra';

$regular: 'AmsiPro-Regular';
$helvetica: 'HelveticaNeueLTStd-LtCn';


/// Ruta para imagenes
$ruta-img: '../images/' !default;


