html {
  box-sizing: border-box;
}
*, *::before, *::after {
  box-sizing: inherit;
}
section{
    overflow: hidden;
    background-image: url('#{$ruta-img}back-texture.png');
	background-size: cover;
}
.hidden{
	display: none !important;
	visibility: hidden !important;
}
// .example {
// 	width: 100%;
// 	background-color: $dark-grey-color;
// 	display: flex;
// 	height: 100vh;
// 	align-items:center;
// 	h1 {
// 		color: $white-color;
// 		width: 100%;
// 		text-align: center;
// 		font-size: 3rem;
// 	}
// 	@include media-width($medium) {
// 		background-color: $blue-color;
// 	}

// 	@include media-width($large) {
// 		background-color: $dark-grey-color;
// 	}

// 	@include media-width($xlarge) {
// 		background-color: $magenta-color
// 	}
// }


.loading{
	
	background-color: rgba(0,0,0,.6);
	width: 100%;
	position: absolute;
	height: 100vh;
	text-align: center;
	padding: 30%;
	z-index: 999999;
	color: white;
}

.letra-especial{
	transition: 700ms;
	font-size: 1.5em; 
	font-weight: bold; 
}

.letra-especial.animada{
	font-size: 2em; 

}

#donaUnaPalabraHeader{
    text-transform: uppercase;
    width: auto;
    color: white;
    background: #f47d20;
    border-radius: 10px;
    font-family: "AmsiProNarw-Bold";
    font-size: 14px;
    padding: 7px;
    text-decoration: none;
    display: inline-block;
    position: relative;
    top: -10px;
}