.modal{
	.modal-content {
		width: 80%;
		margin: 0 auto;
		border: none;
		-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);
		box-shadow: 0 3px 10px rgba(0,0,0,.4);

		.modal-title {
		  position: absolute;
		  width: 115%;
		  left: 50%;
		  top: -30px;
		  transform: translateX(-50%);
		}
		.modal-header {
			padding: 25px;
			border-bottom: none;
			img{
				max-width: 400px;
			}
		    @include media-width($medium) {
				padding: 45px;
		    }
		    .close {
				margin-top: 0;
				position: absolute;
				right: -15px;
				top: -60px;
				background-color: $orange-color;
				opacity: 1;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				line-height: 30px;
				padding: 0;
				@include media-width($small) {
					right: -20px;
					top: -70px;
					width: 40px;
					height: 40px;
					line-height: 40px;
				}
				@include media-width($medium) {
					right: -50px;
		    		top: -30px;
				}
			}
		}
		.modal-body{
			text-align: center;
			color: #454545;
			p{
				margin: 5px 0 5px;
				@include media-width($small) {
					font-size: 16px;
				}
			}
			img.fb-log{
				width: 90%;
				@include media-width($medium) {
					width: auto;
			    	max-height: 50px;
		    	    display: block;
	    			margin: 0 auto;
				}
			}
			/*form de login*/
			.form-group {
				margin-bottom: 10px;
				@include media-width($medium) {
					margin-bottom: 15px;
				}
				label{
					@include fluid-type(12px, 14px)
					margin-bottom: 2px;
					@include media-width($small) {
		    			margin-bottom: 4px;
					}
				}
				.form-control {
					font-family: 'AmsiProNarw-Ultra';
					height: 24px;
					padding: 4px 6px;
					@include fluid-type(14px, 16px);
					color: #555;
					background-color: #e1e1e1;
					border: none;
					border-radius: 2px;
					text-align: center;
					@include media-width($small) {
						height: 30px;
					}
					@include media-width($medium) {
						height: 35px;
					    max-width: 300px;
					    margin: 0 auto;
					}
					&:focus{
						background-color: #ccc;
						border-color: transparent;
						outline: 0;
						-webkit-box-shadow: none;
						box-shadow: none;
					}
				}
			}
		}
		.modal-footer {
			border-top: none;
			text-align: center;
			padding: 5px 0;
			@include media-width($medium) {
				padding: 10px 0;
			}
			.btn-orange {
				position: absolute;
				left: 50%;
				bottom: -15px;
				transform: translateX(-50%);
				@include media-width($medium) {
					min-width: 200px;
				}
			}
		}
		.close {
			font-size: 15px;
			font-weight: 700;
			line-height: 1;
			color: #fff;
			text-shadow: none;
			@include media-width($small) {
				font-size: 20px;
			}
		}
	}
	&#modalDonar{
		.modal-dialog{
		    width: 100% !important;
		    max-width: 100%;
		    height: 60%;
		    margin: 0;
		    bottom: -45%;
		    @include media-width($large){
	    	    height: auto;
			    margin: 0;
			    bottom: -50%;
		    }
		}
		.modal-header{
		    padding: 25px;
			img{
				max-width: 400px;
			}
		}
		.modal-body{
		    /*max-height: 380px;*/
		    overflow: scroll;
		    background-color: #f6f6f6;
		    @include media-width($medium){
		    	max-height: 380px;
		    }
			p{
				font-family: $ultra;
				text-transform: uppercase;
				color: $orange-color;
				display: inline-block;
				font-size: 16px;
				@include media-width($large){
					font-size: 22px;
				}
				&:nth-child(2){
					color: $white-color;
					background-color: $orange-color;
					padding: 5px 10px;
					border-radius: 5px;
					margin-left: 5px;
					span{
						font-size: 20px;
					}
				}
			}
			.form-group{
				p{
					color: $dark-grey-color;
					font-size: 12px;
					text-align: right;
					@include media-width($large){
						font-size: 14px;
					    text-align: right !important;
					    line-height: 43px;
					    width: 100%;
					}
				}
				&:first-child{
					div{
						&:last-child{
							label{
								position: absolute;
							    left: 0;
							    top: 5px;
							    color: $orange-color;
							}
						}
					}
				}
				&:nth-child(2){
					p{
						span{
							font-size: 10px;
							font-family: $regular;
						}
					}
				}
			}
			// &::after{
			//     content: '';
			//     position: absolute;
			//     width: 100%;
			//     background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.24) 99%, rgba(0, 0, 0, 0.15) 100%);
			//     opacity: .7;
			//     height: 14px;
			//     bottom: 0;
			//     left: 0;
			// }
			label{
				display: inline-block;
			}
		}
		.modal-footer{
			padding: 20px 0;
		}
	}
	&#modalGracias{
		h1{
			font-family: $ultra;
			color: $orange-color;
			&.palabra-donada{
				color: $yellow-color;
				margin: 40px 0;
				position: relative;
			    max-width: 100%;
			    font-size: 100%;
			    padding: 10px 0;
			    @include media-width($large){
			    	font-size: 2rem;
			    }
				&:after{
					content: '';
					background-image: url('#{$ruta-img}/donada-before.png');
				    height: 35px;
				    width: 60px;
				    position: absolute;
				    bottom: -30px;
				    background-repeat: no-repeat;
				    left: 50%;
				    transform: translateX(-50%);
				}
				&:before{
					content: '';
					background-image: url('#{$ruta-img}/donada-after.png');
					height: 35px;
				    width: 60px;
				    position: absolute;
				    top: -30px;
				    background-repeat: no-repeat;
				    left: 50%;
				    transform: translateX(-50%);
				}
			}
		}
		p{
			font-family: $ultra;
		}
		a.btn-share{
			color: $white-color;
			background-color: $fb-color;
			margin: 10px 0;
		}
	}
	&#modalInst{
		img{
			max-width: 40px;
		}
		h1, p{
			font-family: $ultra;
			color: $orange-color;
			text-transform: uppercase;
		}
		.gray{
			font-family: $ultra;
			color: $dark-grey-color;
			text-transform: uppercase;
		}
	}
	&#modalBloqueado{
		.modal-header{
			padding: 25px;
		}
		.modal-body{
			padding: 35px 0;
		}
		.gray{
			font-family: $ultra;
			color: $dark-grey-color;
			text-transform: uppercase;
		}
	}
}
.modal{
	&.fade{
		.modal-dialog{
	    	transform: translate(0, -100%);
	    }
	}
	&.show{
		.modal-dialog{
			vertical-align: middle;
	        transform: translate(0, -50%);
    		bottom: -50%;
		}
	}
}
// CHECKBOX
.span_pseudo, 
.chiller_cb span:before, 
.chiller_cb span:after {
	content: "";
	display: inline-block;
	background: #fff;
	width: 0;
	height: 0.2rem;
	position: absolute;
	transform-origin: 0% 0%;
}
.chiller_cb {
	text-align: center;
	position: relative;
	height: 2rem;
	display: flex;
	align-items: center;
    margin: 0 auto;
	max-width: 220px;
	@include media-width($large){
		max-width: 300px;
	}
	input {
		display: none;
		&:checked ~ span {
			background: $orange-color;
			&:before{
				width: 1rem;
				height: 0.15rem;
				transition: width 0.1s;
				transition-delay: 0.3s;
			}
			&:after{
				width: 0.4rem;
				height: 0.15rem;
				transition: width 0.1s;
				transition-delay: 0.2s;
			}
		}
		&:disabled ~ span {
			background: #ececec;
			border-color: #dcdcdc;
		}
		&:disabled ~ label {
			color: #dcdcdc;
			&:hover{
				cursor: default;
			}
		}
	}
	label{
		padding-left: 2.5rem;
		position: relative;
		z-index: 2;
		cursor: pointer;
		margin-bottom:0;
		font-family: inherit;
		font-weight: 300;
	    width: 1.2rem;
    	height: 1.2rem;
	}
	span{
		display: inline-block;
		width: 1.2rem;
		height: 1.2rem;
		border: 3px solid $orange-color;
		position: absolute;
		left: 0;
		transition: all 0.2s;
		z-index: 1;
		box-sizing: content-box;
		border-radius: 5px;
		&:before{
			transform: rotate(-55deg);
			top: 1rem;
			left: 0.37rem;
		}
		&:after {
			transform: rotate(35deg);
			bottom: 0.35rem;
			left: 0.2rem;
		}
	}
}


label.error{
	color: red;	
}
