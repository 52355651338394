#final {

	display: none;
	overflow: scroll;
    background-image: url('#{$ruta-img}final_mobile.jpg');
    background-position: bottom;
	background-size: cover;
	width: 100%;
	height: 100vh;


	@media screen and (min-width: 768px){
		height: 100vh;
		background-image: url('#{$ruta-img}final.jpg');

		.logo-libro{
			margin-top: 50px;
		}
	}


	.logo-libro img{
		width: 80%;
	}

	.logo-kilo img{
		width: 50px;
	}
}


#modalDescarga{

	.btn-orange{

		width: auto;
    	min-width: auto;
		i{
			font-size: 20px;
		}
	}

}